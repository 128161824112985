import React, { useEffect } from 'react'
import { Helmet } from 'react-helmet'
import GivewaysStyle from '../../layouts/GiveawaysStyle'

const Giveaways = () => {
  function getParameterByName(name: string) {
    name = name.replace(/[[]/, '[').replace(/[]]/, ']')
    const regex = new RegExp(`[?&]${name}=([^&#]*)`)
    const results = typeof window === 'undefined' ? null : regex.exec(window.location.search)
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '))
  }
  useEffect(() => {
    const iframe = document.createElement('iframe')
    let shareId = getParameterByName('share_id')
    shareId = shareId ? `&share_id=${shareId}` : ''
    const promoId = getParameterByName('promo_id')
    const campaignId = getParameterByName('campaign_id')
    if (promoId) {
      iframe.src = `//landing.dojomojo.com/landing/campaign/${campaignId}${window.location.search}${shareId}`
    } else {
      iframe.src = `//www.dojomojo.com/landing/campaign/${campaignId}`
    }
    const body = document.getElementsByTagName('body')[0]
    body.insertBefore(iframe, body.firstChild)

    const ua = window.navigator.userAgent
    const msie = ua.indexOf('MSIE ')
    if (msie > 0) {
      if (parseInt(ua.substring(msie + 5, ua.indexOf('.', msie))) < 10) {
        alert('Looks like this browser is not supported so the elements on this page may not display properly.')
      }
    }
  }, [])

  const title = getParameterByName('title') || 'Grummies'
  const ogImage = getParameterByName('share_image')
  const ogDescription = getParameterByName('share_message')

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        {Boolean(ogImage) && <meta property="og:image" content={ogImage} />}
        {Boolean(ogDescription) && <meta name="description" content={ogDescription} />}
        {Boolean(ogDescription) && <meta property="og:description" content={ogDescription} />}
        <meta name="robots" content="noindex" />
      </Helmet>
      <GivewaysStyle />
    </>
  )
}

export default Giveaways
