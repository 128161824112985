import { createGlobalStyle } from 'styled-components'

const GiveawaysStyle = createGlobalStyle`
  html {
    height: 100%;
  }
  body {
    margin: 0px;
    height: 100%;
    overflow: hidden;
  }
  iframe {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
  }
`

export default GiveawaysStyle
